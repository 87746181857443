// Id-tags for elements that are part of the instance's header/footer-theme,
// that should be updated when the user changes locale from within the app.
//
// Values for tags that end with '-url' are inserted as href-values for the
// relevant link element.
// For example; The value of 'help-link-url' will be the new href-value
// for the element <a id="help-link"></a>.
const localizedElements = {
  nb: {
    "site-title": "Passordtjenester",
    "app-title": "Passordtjenester",
    "toggle-language": "English",
    "help-link": "Hjelp",
    "help-link-url":
      "https://www.nih.no/tjenester-og-verktoy/tjenester-og-fasiliteter/ikt/brukernavn-og-passord/",
    "contact-info-header": "Kontaktinformasjon",
    "maintainer-header": "Ansvarlig for denne tjenesten",
    "maintainer-link": "IT-avdelingen",
    "maintainer-link-url":
      "https://www.nih.no/it",
    "header-logo-pic-src": "NIH-logo-no.svg",
    "footer-logo-pic-src": "NIH-logo-no-white.svg",
    "uu-status-link":
      "Tilgjengelighetserklæring",
    "terms-header":
      "Vilkår",
    "terms-cookies-link":
      "Informasjonskapsler",
    "terms-cookies-link-url":
      "https://www.uio.no/tjenester/it/brukernavn-passord/passordtjenester/hjelp/informasjonskapsler-i-passordtjenester.html",
    "logout-link": "Logg ut",
    "oidc-provider": "Innlogget med ID-porten"
  },
  en: {
    "site-title": "Password services",
    "app-title": "Password services",
    "toggle-language": "Norsk",
    "help-link": "Help",
    "help-link-url":
      "https://www.nih.no/tjenester-og-verktoy/tjenester-og-fasiliteter/ikt/brukernavn-og-passord/",
    "contact-info-header": "Contact info",
    "maintainer-header": "Maintained by",
    "maintainer-link": "IT Department",
    "maintainer-link-url":
      "https://www.nih.no/it",
    "header-logo-pic-src": "NIH-logo-en.svg",
    "footer-logo-pic-src": "NIH-logo-en-white.svg",
    "uu-status-link":
      "Accessibility statement",
    "terms-header":
      "Terms",
    "terms-cookies-link":
      "Cookies",
    "terms-cookies-link-url":
      "https://www.uio.no/english/services/it/username-password/password-services/help/cookies-in-password-services.html",
    "logout-link": "Log out",
    "oidc-provider": "Logged in using ID-porten"
  }
};

export default {
  appSettings: {
    defaultLocale: "nb",
    minPasswordScore: 32,
    maxPasswordLength: Infinity,
    SMSTokenRequiresId: false,
    reCaptchaEnabled: false,
    reCaptchaKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    showFeedbackPage: false,
    lowerCaseUsernameOnly: true,
    showIntroPage: false,
    changePasswordEnableNorwegianLettersInfo: true,
    disableNewUserPage: false,
    stripLastAtSignAndRestFromUsername: false,
    forgottenUsernameLinkOnNewUserPage: true,
    otpEnabled: false,
    mfaResetEnabled: false,
    pwnedPasswordsEnabled: false,
    debounceDelay: 500
  },
  apiSettings: {
    apiBaseUrl: "/api",
    tokenExpiresAfter: 600,
    refreshTokenThreshold: 60,
    tokenExpiryWarningThreshold: 40,
    logoutEndpoint: "/logout",
    reqPassTokenByLoginEndpoint: "/authenticate",
    reqPassTokenBySMSTokenEndpoint: "/sms/verify",
    reqSMSTokenEndpoint: "/sms",
    reqUsernamesEndpoint: "/list-usernames",
    updatePasswordEndpoint: "/password",
    refreshTokenEndpoint: "/renew",
    userInfoEndpoint: "/user-info",
    oidc: {
      idporten: {
        logoutEndpoint: "/oidc/logout/idporten",
        reqAuthEndpoint: "/oidc/authorize/idporten"
      },
      reqUsernamesEndpoint: "/oidc/list-usernames",
      updatePasswordEndpoint: "/oidc/password"
    },
    hibp: {
      hibpEndpoint: "/hibp/"
    }
  },
  localizedElements,
  // Localized texts that are used from within the application.
  // These will be merged into the object returned by translations.js.
  // The tags will be available directly at the top-level from the
  // currently selected locale.
  appTexts: {
    nb: {
      passwordDelay:
        "Det kan ta inntil en time før passordet vil fungere for alle tjenester ved NIH.",
      forgottenPasswordInfo:
        "Fyll inn feltene og få en engangskode på SMS. Mobilnummeret må være registrert i NIHs studentsystem, StudentWeb, eller i ansattsystemet.",
      newUserInfo:
        "Fyll inn feltene og få en engangskode på SMS. Mobilnummeret må være registrert i NIHs studentsystem, StudentWeb, eller i ansattsystemet.",
      requestUsernamesError:
        "Vi kunne ikke identifisere deg ut ifra oppgitt informasjon. Vennligst sjekk at du har fylt inn riktig nummer.",
      general: {
        log_in: "Bytt passord",
        password: "Nåværende passord"
      }
    },
    en: {
      passwordDelay:
        "It may take up to 30 minutes before your password will work with all services at NIH.",
      forgottenPasswordInfo:
        "Fill out the form and receive a one-time token by SMS. In order to use this service, your mobile phone number must be registrered in NIH's student system, StudentWeb, or in the employee system.",
      newUserInfo:
        "Fill out the form and receive a one-time token by SMS. In order to use this service, your mobile phone number must be registrered in NIH's student system, StudentWeb, or in the employee system.",
      requestUsernamesError:
        "We were unable to identify you based on the supplied information. Please verify that you have filled out the correct data.",
      general: {
        log_in: "Change password",
        password: "Current password"
      }
    }
  },
  changePasswordTexts: {
    nb: [
      "Du kan bygge opp passordet slik du vil. Vi anbefaler bruk av passfraser, dvs. at passordet er en setning.",
      "Styrkeindikatoren viser når passordet er godkjent.",
      // 'Les mer om <a class="message-list__item__link" href="https://www.uia.no/student/it-hjelp/brukernavn-passord-og-brukeradministrasjon/">krav til passord</a>.',
      "Det kan ta inntil en time før passordet vil fungere for alle tjenester ved NIH."
    ],
    en: [
      "Your password can be structured as you see fit. We recommend using passphrases, i.e. using a sentence as a password.",
      "The strength indicator will show when the password is strong enough.",
      // 'Read more about <a class="message-list__item__link" href="https://www.uia.no/en/student/it-services/user-account">password requirements</a>.',
      "It may take up to up to an hour before your password will work with all services at NIH."
    ]
  },
  changePasswordNorwegianLettersInfo: {
    nb:
      "Det kan oppstå problemer dersom du bruker særnorske tegn som æ, ø og å, samt en del andre tegn i passordet.",
    en:
      "Problems may occur if you use certain characters like æ, ø and å, as well as other special characters in the password."
  },

  // Hook functions for updating the site container when the user triggers
  // a change to the application settings, and vice versa via setting up
  // event-listeners on elements located in the site-container.
  hookFunctions: {
    updateLanguage: function (lang) {
      for (var id in localizedElements[lang]) {
        if (localizedElements[lang].hasOwnProperty(id)) {
          if (id.substring(id.length - 4, id.length) === "-url") {
            // Look up element the current id without the '-url'-postfix and set
            // the value of the id-key as the new href.
            document.getElementById(id.substring(0, id.length - 4)).href =
              localizedElements[lang][id];
          } else if (id.substring(id.length - 4, id.length) === "-src") {
            // Look up element the current id without the '-src'-postfix and set
            // the value of the id-key as the new src.
            document.getElementById(id.substring(0, id.length - 4)).src =
              localizedElements[lang][id];
          } else {
            document.getElementById(id).innerHTML = localizedElements[lang][id];
          }
        }
      }
    },
    attachToggleLanguageListener(callback) {
      document
        .getElementById("toggle-language")
        .addEventListener("click", callback);
    }
  }
};
